<template>
  <CRow>
    <CCol col="12">
      <CCard v-if="itemData">
        <CCardHeader> المقالة رقم: {{ $route.params.id }} </CCardHeader>
        <CCardBody>

          <CAlert color="info">
            <CRow>
              <CCol col="3">التصنيف</CCol>
              <CCol col="3"> {{ itemData.category }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3">المحامي</CCol>
              <div v-html="itemData.lawyer"></div>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="3">العنوان</CCol>
              <CCol col="3"> {{ itemData.title }} </CCol>
            </CRow>
          </CAlert>

          <CAlert color="info">
            <CRow>
              <CCol col="2">الوصف </CCol>
              <CCol col="10">
                <div v-html="itemData.description"></div>
              </CCol>
            </CRow>
          </CAlert>


          <CAlert color="info">
            <CRow>
              <CCol col="3"> الحالة </CCol>
              <CCol col="3">
                  <b v-if="(itemData.status == 1)" :class="`text-${getBadge(itemData.status)}`">
                    جديد
                  </b>
                  <b v-else-if="(itemData.status == 2)" :class="`text-${getBadge(itemData.status)}`">
                    نشط
                  </b>
                  <b v-else :class="`text-${getBadge(itemData.status)}`">
                    متوقف
                  </b>
                

                <CButton v-if="itemData.status == 1" class="mx-2" color="success" @click="updateStatus(2, itemData.id)">
                  تفعيل
                </CButton>
                <CButton v-else-if="itemData.status == 3 || itemData.status == 3" class="mx-2" color="success"
                  @click="updateStatus(2, itemData.id)">
                  تفعيل
                </CButton>
                <CButton v-else color="danger" class="mx-2" @click="updateStatus(3, itemData.id)">
                  ايقاف
                </CButton>
              </CCol>
            </CRow>
          </CAlert>
          
          <CAlert color="info">
            <CRow>
              <CCol col="3"> الصورة </CCol>
              <CCol col="3">
                <CImg
                      :src="itemData.image_url"
                      fluid
                    />
              </CCol>
            </CRow>
          </CAlert>

        </CCardBody>
        <CCardFooter>
          <CButton color="info" @click="goBack">رجوع</CButton>

        </CCardFooter>
      </CCard>
      <Spinner v-else />
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "Article",
  data() {
    return {
      itemData: null,
      usersOpened: null,
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.toggleLoadSpinner();

      this.$http
        .get("articles/" + this.$route.params.id)
        .then((res) => {
          // handle success
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          //
          this.toggleLoadSpinner();
        });
    },
    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },
    goBack() {
      this.$router.push({ path: "/articles" });
    },
    getBadge(status) {
      switch (status) {
        case 2:
          return "success";
        case 3:
          return "danger";
        default:
          return "primary";
      }
    },

    updateStatus(status, id) {
      this.toggleLoadSpinner();
      this.$http
        .put(`/articles/status/${id}`, { status })
        .then((res) => {
          this.itemData = res.data.data;
          this.toggleLoadSpinner();
        })
        .catch((error) => {
          // handle error
          this.toggleLoadSpinner();
        });
    }
  },
};
</script>
